/* Dashboard.css */

#dashboard {
  height: 100%;
  width: 100%;
  background-color: rgba(3, 3, 27, 0.7);
}

#dashboard .grid {
  padding: 0.5rem;
  padding-top: 0;
  display: grid;
  /* grid-template-rows: 1fr 1fr; */
  gap: 1rem;
}

#dashboard .grid .roww {
  display: grid;
  grid-template-columns: 2fr 1fr;
  gap: 1rem;
}

#dashboard .grid .row2 {
  display: grid;
  /* grid-template-columns: 1fr 2fr; */
  gap: 1rem;
}

@media screen and (min-width: 280px) and (max-width: 1080px) {
  #dashboard {
    height: max-content;
  }

  #dashboard .grid {
    grid-template-columns: 1fr;
    padding: 1rem;
    height: max-content;
  }

  #dashboard .grid .row,
  #dashboard .grid .row2 {
    grid-template-columns: 1fr;
  }
}
