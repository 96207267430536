/* AdminDashboard.css */

body {
  background-color: #121212;
  color: #e0e0e0;
  font-family: "Arial", sans-serif;
}

.admin-container {
  padding: 20px;
}

.ticket-card {
  background: #000 !important;
  padding: 15px;
  margin: 10px 0;
  border-radius: 8px;
  color: #fff !important;
  text-decoration: none;
  transition: background 0.3s;
}

.ticket-card:hover {
  background: #272727;
}

h2 {
  text-align: center;
  margin-bottom: 20px;
}

.chat-container {
  padding: 20px;
}

.chat-messages {
  height: 70vh;
  overflow-y: auto;
  background: #1e1e1e;
  padding: 10px;
  border-radius: 8px;
}

.chat-bubble {
  padding: 10px;
  /* margin: 10px 0; */
  border-radius: 8px;
  width: 70%;
  word-wrap: break-word;
}

.sent {
  background: #d60d57;
  align-self: flex-end;
  color: #fff;
}

.received {
  background: #272727;
  color: #fff;
}

.chat-input {
  display: flex;
  gap: 10px;
  margin-top: 20px;
}

.chat-input input {
  flex-grow: 1;
  padding: 10px;
  border-radius: 8px;
  border: none;
  background: #1e1e1e;
  color: #fff;
}

.chat-input button {
  padding: 10px 15px;
  background: rgb(214 13 87);
  border: none;
  border-radius: 8px;
  color: white;
  cursor: pointer;
}

.close-btn {
  background: #e53935;
}

.chat-input button:hover {
  background: #ff3366;
}

.admin-container {
  margin: 30px 20px;
  background-color: #121212;
  color: #fff;
  padding: 20px;
  border-radius: 8px;
}

.title {
  text-align: center;
  margin-bottom: 20px !important;
}

.ticket-stats {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.stat-card {
  text-align: center;
  border-radius: 8px;
}

.tickets-list {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px;
}

.ticket-card {
  padding: 15px;
  border-radius: 8px;
  background-color: #1e1e1e;
  color: #fff;
  text-decoration: none;
  transition: 0.3s ease-in-out;
  box-shadow: 0 4px 6px rgba(255, 255, 255, 0.1);
  border-left: 5px solid #00aaff;
}

.ticket-card:hover {
  background-color: #292929;
  transform: scale(1.02);
  box-shadow: 0 6px 10px rgba(255, 255, 255, 0.2);
}

.ticket-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.status {
  font-weight: bold;
  padding: 5px 10px;
  border-radius: 5px;
  text-transform: capitalize;
}

.status.open {
  background-color: red;
  color: white;
}

.status.in.progress {
  background-color: orange;
  color: white;
  height: 30px;
}

.status.closed {
  background-color: green;
  color: white;
}

.ticket-date {
  font-size: 14px;
  opacity: 0.8;
  margin-bottom: 10px;
}

.artist-details {
  margin-top: 10px;
  font-size: 14px;
  background: rgba(255, 255, 255, 0.1);
  padding: 8px;
  border-radius: 5px;
}

a.ticket-card.card {
  background-color: #333;
  color: #fff;
}

.table-container {
  background-color: #1e1e1e;
  padding: 15px;
  border-radius: 8px;
  overflow-x: auto;
}

.tickets .table {
  color: #fff;
}

.tickets .table th {
  background-color: #333;
  color: #fff;
  text-align: center;
}

.tickets .table td {
  text-align: center;
}

.tickets .ticket-link {
  color: rgb(214 13 87);
  text-decoration: none;
  font-weight: bold;
}

.tickets .ticket-link:hover {
  text-decoration: underline;
}

.admin-chat-container {
  margin: 30px 20px;
  background-color: #121212;
  color: #fff;
  padding: 20px;
  border-radius: 8px;
}

/* Ticket Details Card */
.ticket-details-card {
  background-color: #1e1e1e;
  color: #fff;
  border: 1px solid #333;
  margin-bottom: 20px;
}

.ticket-details-card .card-body {
  padding: 15px;
}

.ticket-details-card .status {
  font-weight: bold;
  padding: 5px 10px;
  border-radius: 5px;
  text-transform: capitalize;
}

.status.open {
  background-color: red;
  color: white;
}

.status.in-progress {
  background-color: orange;
  color: white;
}

.status.closed {
  background-color: green;
  color: white;
}

/* Chat Messages */
.chat-messages {
  max-height: 400px;
  overflow-y: auto;
  padding: 10px;
  background: #1e1e1e;
  border-radius: 8px;
  margin-bottom: 15px;
}

.chat-bubble {
  max-width: 60%;
  padding: 10px 15px;
  border-radius: 8px;
  margin-bottom: 10px;
  font-size: 14px;
  display: inline-block;
}

.sent {
  background-color: #007bff;
  color: white;
  align-self: flex-end;
  text-align: right;
}

.received {
  background-color: #333;
  color: white;
  align-self: flex-start;
  text-align: left;
}

/* Chat Input */
.chat-input {
  display: flex;
  align-items: center;
  gap: 10px;
}

.chat-input input {
  flex: 1;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #444;
  background: #222;
  color: white;
}

.chat-input button {
  padding: 10px 15px;
  border-radius: 5px;
  width: 100%;
}

.close-btn {
  background-color: rgb(214 13 87);
  border: none;
  color: white;
}

.close-btn:hover {
  background-color: rgb(214 13 87);
}

.admin-chat-container {
  /* max-width: 800px; */
  margin: auto;
  padding: 20px;
  background: #121212;
  color: white;
  border-radius: 8px;
}

.ticket-details-card, .artist-details-card {
  margin-bottom: 20px;
}

.chat-messages {
  max-height: 400px;
  overflow-y: auto;
  padding: 10px;
  background: #222;
  border-radius: 8px;
}

.chat-bubble {
  padding: 5px;
  border-radius: 10px;
  margin-bottom: 5px;
  max-width: 70%;
}

.sent {
  background: #007bff;
  color: white;
  align-self: flex-end;
  float: right;
}

.received {
  background: #444;
  color: white;
  align-self: flex-start;
}

.chat-input {
  display: flex;
  gap: 10px;
  margin-top: 10px;
}

/* .close-btn {
  margin-left: 10px;
} */
