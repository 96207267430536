/* App.css */
.app-container {
  display: grid;
  grid-template-columns: 1fr 16fr;
  min-height: 100vh;
  height: max-content;
}

@media screen and (min-width: 280px) and (max-width: 1080px) {
  .app-container {
    grid-template-columns: 1fr;
    height: max-content;
  }

  .no_sidebar {
    grid-template-columns: 1fr;
  }
}

.no_sidebar {
  grid-template-columns: 1fr;
}

mark {
  -webkit-text-fill-color: #0000;
  animation: gradient 15s ease infinite;
  background: linear-gradient(-45deg, #00ef86, #008cff, #ec3258, #fcda00);
  -webkit-background-clip: text;
  background-size: 400% 400%;
  color: #fff;
  text-decoration: none;
}

p,
h1,
h2,
h3,
h4,
h5,
h6,
span {
  margin: unset !important;
}

.theme-btn {
  background: linear-gradient(135deg, #8338ec, #2196f3);
}

.table-dark {
  --bs-table-bg: #000 !important;
}

.artists-table .dropdown-menu {
  position: absolute;
}

.artists-table .btn-success{
  background-color: #157347 !important;
}
/* 
@media (min-width: 576px) {
  .container{
      max-width: 95%;
  }
}
@media (min-width: 768px) {
  .container{
      max-width: 95%;
  }
}
@media (min-width: 992px) {
  .container{
      max-width: 95%;
  }
}
@media (min-width: 1200px) {
  .container{
      max-width: 95%;
  }
}
@media (min-width: 1400px) {
  .container{
      max-width: 95%;
  }
} */

.pagination{
  align-items: center;
}
.pagination span.mx-3{
  color: white;
}